export default {
    data() {
        return {
            show: [],
            servicesItems: [
                {
                    title: "招聘服务",
                    englishTitle: "Human Resource Service",
                    description: [
                        "招聘信息委托发布",
                        "招聘流程外包服务",

                    ],
                    url: this.$nginxUrl + "service/compression/partner.jpg",
                },
                {
                    title: "人事代理",
                    englishTitle: "Human Resource Agency",
                    description: ["档案整理", "职称评定", "户口挂靠"],
                    url: this.$nginxUrl + "service/compression/document.jpg",
                },
                {
                    title: "劳务派遣",
                    englishTitle: "Labor Dispatch",
                    description: [
                        "劳务派遣单位与劳动者建立劳动合同关系，根据用工单位的需求将其派至相应的岗位工作，为用工单位提供从招聘选拔、入职培训、办理社会保险、薪酬福利核算发放、劳务纠纷处理等一体式用工服务。",
                    ],
                    url: this.$nginxUrl + "service/compression/factory1.jpg",
                },
                {
                    title: "岗位外包服务",
                    englishTitle: "Post-sourcing Service",
                    description: ["根据客户单位灵活岗位需求，提供全流程的管理和服务"],

                    url: this.$nginxUrl + "service/compression/factory.jpg",
                },
                {
                    title: "业务外包服务",
                    englishTitle: "Business Outsourcing Service",
                    description: [
                        "生产线服务",
                        "物流服务",
                        "加工服务",
                        "驾驶服务",
                        "后勤服务等",
                    ],
                    url: this.$nginxUrl + "service/compression/hospital.jpg",
                },
                {
                    title: "高级人才寻访",
                    englishTitle: "Manufacturing Contract Service",
                    description: [
                        "协同客户引进高端人才",
                        "组建核心研发团队",
                        "助力技术突破与产品创新",
                        "推动新质生产力发展",
                    ],
                    url: this.$nginxUrl + "service/compression/shaking-hands.jpg",
                },
                {
                    title: "软件技术服务",
                    englishTitle: "Business and Tax Agency",
                    description: ["自主研发", "安全可控", "高效稳定", "行业领先"],
                    url: this.$nginxUrl + "service/compression/code.jpg",
                },
                {
                    title: "其他人力资源服务",
                    englishTitle: "Other Human Resource Service",
                    description: [
                        "管理咨询服务",
                        "培训服务",
                        "薪酬福利外包",
                        "劳动政策共享服务",
                        "规章制度设计等"
                    ],
                    url: this.$nginxUrl + "service/compression/work1.jpg",
                },
            ],
        };
    },
    created() {
        this.show = new Array(10).fill(false)
    },
    methods: {
        check(index) {
            this.show[index] = this.$set(this.show, index, !this.show[index]);
        }
    }
};