<template>
  <div
    class="organization-div ma-2"
    :class="{ mobile: !$vuetify.breakpoint.xl }"
  >
    <v-img
      max-height="650"
      :contain="true"
      src="http://47.108.77.156:9000/data/company/organization-new.png"
    ></v-img>
  </div>
</template>

<script>
import index from "./index";
export default index;
</script>

<style lang="scss" scoped>
@import "./index.scss";
@import "./index.mobile.scss";
</style>
