
import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import axios from 'axios';
import captionWidget from './component/caption-widget/index.vue';
import closeDialogButton from './component/close-dialog-button/index.vue';
import VueTilt from 'vue-tilt.js'
import VueClipboard from 'vue-clipboard2'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import { getUrlKey } from '@/utils/getUrlKey';
import { getData } from '@/utils/global';

import {
  download
} from './utils/general-tool';

// const nginxUrlDev = 'http://192.168.18.77:9000/imgs/';
const nginxUrl = 'http://47.108.77.156:9000/data/';
const nginxFileUrl = 'http://47.108.77.156:9000';
const official = 'http://www.cqwyrc.cn/';
// const official = 'http://192.168.18.77:8080/';

//字典
Vue.prototype.$global = getData()

Vue.prototype.$axios = axios;
Vue.prototype.$getUrlKey = getUrlKey;

Vue.config.productionTip = false;
Vue.prototype.download = download;
Vue.prototype.$nginxUrl = nginxUrl;
Vue.prototype.$nginxFileUrl = nginxFileUrl;
Vue.prototype.$official = official;

Vue.use(VueTilt)

Vue.use(VueClipboard);


Vue.component(
  'caption-widget', captionWidget,
);
Vue.component(
  'close-dialog-button', closeDialogButton
);

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app');
