export default {
    name: "company-qualification",
    components: {},
    data: function () {
        return {
            qualificationItem: [
                {
                    title: "2021年度- 2023年度 A级纳税企业",
                    subtitle: "国家税务总局重庆市渝中区税务局",
                    url: "Grade A tax enterprise in 2021-2023.png",
                    time: "2024年6月",
                },
                {
                    title: "国家级人力资源服务出口基地",
                    subtitle: "重庆市人力资源开发服务中心",
                    url: "Chongqing human resources development Service center.jpg",
                    time: "2025年1月",
                },
                {
                    title: "2024年度重庆市人力资源服务助力制造业高质量发展优秀单位",
                    subtitle: "重庆市人才研究和人力资源服务协会",
                    url: "2024 to help outstanding units in manufacturing.jpg",
                    time: "二零二五年一月",
                },
                {
                    title: "引才突出贡献企业奖",
                    subtitle: "被渝中区委",
                    url: "Citing talents outstanding contribution.png",
                    time: "2025",
                },
                {
                    title: "2022-2023年度人力资源服务业十强机构",
                    subtitle: "重庆市人才研究和人力资源服务协会",
                    url: "human resource Service Industry in 2022-2023.jpg",
                    time: "2024年",
                },
                {
                    title: "重庆市人力资源服务机构等级评定AAAAA级",
                    subtitle: "重庆市人才研究和人力资源服务协会",
                    url: "human resources service organization rating AAAAA.jpg",
                    time: "2024年",
                },
                {
                    title: "2023年度渝中金融杰出服务奖",
                    subtitle: "中共重庆市渝中区委重庆市渝中区人民政府",
                    url: "2023 Yuzhong Financial Outstanding Service.jpg",
                    time: "2024年",
                },
                {
                    title: "2023年度A级纳税企业",
                    subtitle: "国家税务总局重庆市渝中区税务局",
                    url: "Grade A tax enterprise in 2023.png",
                    time: "2024年5月",
                },
                {
                    title: "2022年度人力资源服务业骨干企业",
                    subtitle: "重庆市人力资源和社会保障局",
                    url: "2022 human resource service industry.png",
                    time: "2023年",
                },
                {
                    title: "2021年度A级纳税企业",
                    subtitle: "国家税务总局重庆市渝中区税务局",
                    url: "Grade A tax enterprise in 2021.png",
                    time: "2022年9月",
                },
                {
                    title: "2020年度A级纳税企业",
                    subtitle: "国家税务总局重庆市渝中区税务局",
                    url: "Grade A tax enterprise in 2020.png",
                    time: "2021年7月",
                },
                {
                    title: "优秀引才企业",
                    subtitle: "中共渝中区委人才工作领导小组办公室",
                    url: "Excellent talent enterprise.png",
                    time: "2021年",
                },
                {
                    title: "重庆市和谐劳动关系AAA级企业",
                    subtitle: "重庆市人力资源和社会保障局、重庆市总工会、重庆市企业联合会（企业家协会）、重庆市工商业联合会",
                    url: "Chongqing harmonious labor relations AAA.png",
                    time: "2020年",
                },
                {
                    title: "2018-2019年度十佳人力资源服务机构",
                    subtitle: "重庆市人才研究和人力资源服务协会",
                    url: "2018-2019 Top 10 HR Service Agencies.png",
                    time: "二零二零年八月",
                },
                {
                    title: "2018-2019年度人力资源服务机构派遣外包十强",
                    subtitle: "重庆市人才研究和人力资源服务协会",
                    url: "2018-2019 HR service agency dispatch outsourcing Top 10.png",
                    time: "二零二零年八月",
                },
                {
                    title: "2019年渝中区人力资源服务业经济发展贡献企业",
                    subtitle: "重庆市渝中区人力资源和社会保障局",
                    url: "CQTC2020.png",
                    time: "2020",
                },
                {
                    title: "2018年度成长型企业50强",
                    subtitle: "中共重庆市渝中区委、重庆市渝中区人民政府",
                    url: "Top 50 Growth Companies.jpg",
                    time: "二〇一九年三月",
                },
                {
                    title: "2015-2016年重庆市人力资源诚信服务示范单位",
                    subtitle: "重庆市人力资源和社会保障局",
                    url: "Integrity Service Demonstration Institution.jpg",
                    time: "2017年6月2日",
                },
                {
                    title: "重庆市中小商贸流通企业公共服务平台服务机构",
                    subtitle: "重庆市中小商贸流通企业服务中心",
                    url: "Service organization.jpg",
                    time: "二〇一五年",
                },
                {
                    title: "重庆市人力资源协会副会长单位",
                    subtitle: "ChongQing Human Resourece Association",
                    url: "Vice President Unit.jpg",
                    time: "",
                },
                {
                    title: "工人先锋号",
                    subtitle: "重庆市渝中区总工会",
                    url: "Worker Pioneer.jpg",
                    time: "二〇一七年四月",
                },
                {
                    title: "二〇一四年重庆市人力资源服务机构诚信示范单位",
                    subtitle: "重庆人力资源协会、华龙网",
                    url: "Integrity Demonstration Unit of Human Resources Service Organization.jpg",
                    time: "二〇一四年六月十二日",
                },
                {
                    title: "2016-2017年度人力资源服务机构十强(派遣外包类)",
                    subtitle: "重庆市人才研究和人力资源服务协会",
                    url: "Top Ten Human Resources Service Organizations.jpg",
                    time: "二〇一八年",
                },
                {
                    title: "二〇一五年度重庆市十佳人力资源服务机构",
                    subtitle: "重庆市人力资源协会",
                    url: "Top Ten Human Resources Service Organizations 2015.jpg",
                    time: "二〇一六年四月",
                },
                {
                    title: "先进基层党组织",
                    subtitle: "中共重庆市社会流动人才市场委员会",
                    url: "Advanced grassroots party organization.jpg",
                    time: "二〇一三年六月",
                },
                {
                    title: "渝中区定点职业培训机构",
                    subtitle: "重庆市渝中区就业和人才服务局",
                    url: "Designated Vocational Training Institutions in Yuzhong District.jpg",
                    time: "二〇一八年一月",
                },
                {
                    title: "质量管理体系认证证书",
                    subtitle: "中标联合(北京)认证有限公司",
                    url: "Quality management system certification.jpg",
                    time: "",
                },
                {
                    title: "环境管理体系认证证书",
                    subtitle: "中标联合(北京)认证有限公司",
                    url: "ISO14001.jpg",
                    time: "",
                },
                {
                    title: "职业健康安全管理体系认证证书",
                    subtitle: "中标联合(北京)认证有限公司",
                    url: "ISO45001.jpg",
                    time: "",
                },
                {
                    title: "重庆市高校毕业生就业见习基地",
                    subtitle:
                        "重庆市人力资源和社会保障局,重庆市就业再就业工作领导小组办公室,重庆市财政局",
                    url: "Chongqing University Graduate Employment Training Base.jpg",
                    time: "",
                },
                {
                    title: "重庆市人力资源协会劳务派遣分会",
                    subtitle:
                        "Labour Dispatch Services Branch of Chongqing Human Resource Association",
                    url: "Labor Dispatch Branch of Chongqing Human Resources Association.jpg",
                    time: "",
                },
            ],
            dialog: [],
            mobileDialog: false,
            dialogUrl: '',
        };
    },
    methods: {
        openDialog(url) {
            this.mobileDialog = !this.mobileDialog
            this.dialogUrl = url
        }
    },
};