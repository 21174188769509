
export default {
    name: "company-history",
    data() {
        return {
            boxWidth: 368,
            companyHistory: [
                {
                    year: "2002",
                    historyEvens: ["人才大市场大坪分市场成立"],
                },
                {
                    year: "2004",
                    historyEvens: ["重庆万友人才服务有限公司成立"],
                },
                {
                    year: "2006",
                    historyEvens: ["公司全面开展劳务派遣业务"],
                },
                {
                    year: "2007",
                    historyEvens: [
                        "公司规范业务流程和管理制度，构建专业化、系统化的服务体系",
                        "开展猎头、咨询、培训",
                    ],
                },
                {
                    year: "2009",
                    historyEvens: ["运行自主研发的人力资源服务系统"],
                },
                {
                    year: "2010",
                    historyEvens: ["全面布局各区县办事处，形成1小时服务圈"],
                },
                {
                    year: "2011",
                    historyEvens: ["广泛推行业务外包、岗位外包"],
                },
                {
                    year: "2012",
                    historyEvens: ["《万友人才报》出刊"],
                },
                {
                    year: "2013",
                    historyEvens: ["提供企业员工宿舍保障及管理"],
                },
                {
                    year: "2015",
                    historyEvens: ["成立万友培训学校、友一家政公司、万众人力资源公司"],
                },
                {
                    year: "2017",
                    historyEvens: [
                        "公司总部搬迁至英利国际广场；成立第一分公司、成都分公司、万优人力资源公司",
                    ],
                },
                {
                    year: "2018",
                    historyEvens: ["被授予2018年“成长型企业50强”称号"],
                },
                {
                    year: "2019",
                    historyEvens: [
                        "成立万友信成外包公司、贵阳分公司、万盛分公司；被评为“2019年渝中区人力资源服务业经济发展贡献企业”",
                    ],
                },
                {
                    year: "2020",
                    historyEvens: [
                        "成立大渡口分公司；成为渝中区当年唯一获得“AAA”级和谐劳动关系企业；“跨界”开展人力资源以外的业务",
                    ],
                },
                {
                    year: "2021",
                    historyEvens: [
                        "被授予“经营创新型企业”、“优秀引才企业”、“A级纳税企业”、“纳税明星企业”",
                    ],
                },
                {
                    year: "2022",
                    historyEvens: [
                        "成立第二分公司、第三分公司、第四分公司", "被评定为“A级纳税企业”“重庆市人力资源服务业骨干企业”“重庆市人力资源服务业十强机构”"
                    ],
                },
                {
                    year: "2023",
                    historyEvens: [
                        "成立内蒙古分公司、成都天府新区分公司", "被授予“重庆市就业创业工作先进集体”"
                    ],
                },
                {
                    year: "2024",
                    historyEvens: [
                        "被认定为“5A级人力资源服务机构”", "被授予“重庆市人力资源服务业十强机构”"
                    ],
                },
                {
                    year: "2025",
                    historyEvens: [
                        "被授予“国家级人力资源服务出口基地”。",
                        "被授予“2024年度重庆市人力资源服务助力制造业高质量发展优秀单位”。"
                    ],
                },
            ],
            companyHistoryDesc: [],
            currentYear: 0,
            currentLeft: 0,
            ticksLabels: ["现在", "成熟阶段", "发展阶段", "创立阶段"],
            isIntersecting: false,
            parallaxGrayscale: 0,
        };
    },
    watch: {
        currentLeft(val) {
            if (val <= this.boxWidth) {
                this.currentYear = 0;
            } else if (val <= this.boxWidth * 2) {
                this.currentYear = 1;
            } else if (val <= this.boxWidth * 9) {
                this.currentYear = 2;
            } else {
                this.currentYear = 3;
            }
        },
        currentYear(val) {
            if (val == 0) {
                if (event.button == 0)
                    document.getElementById("description").scrollLeft = 0;
            } else if (val == 1) {
                if (event.button == 0)
                    document.getElementById("description").scrollLeft = this.boxWidth * 2;
            } else if (val == 2) {
                if (event.button == 0)
                    document.getElementById("description").scrollLeft = this.boxWidth * 6;
            } else {
                if (event.button == 0)
                    document.getElementById("description").scrollLeft = this.boxWidth * 10;
            }
        },
    },
    created() {
        this.companyHistoryDesc = this.companyHistory.reverse();
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },
    methods: {
        onIntersect(entries) {
            this.isIntersecting = entries[0].isIntersecting;
        },
        handleScroll() {
            //获取滚动时的高度
            if (this.isIntersecting) {
                let scrollTop =
                    window.pageYOffset ||
                    document.documentElement.scrollTop ||
                    document.body.scrollTop;
                let scrollParallax = this.$refs.scrollParallax.offsetTop;
                let num = scrollTop - scrollParallax + 1000;

                if (num <= 1000 && num >= 0) {
                    let result = (num / 10).toFixed(0);
                    this.parallaxGrayscale =
                        200 - 2 * result >= 100 ? 100 : 200 - 2 * result;
                }
            }
        },
    },

};